/* eslint-disable react/no-unescaped-entities */
import React, { useRef } from 'react';
import { gsap } from 'gsap';
import useIsomorphicLayoutEffect from '@/utils/useIsomorphicEffect';
import TitleShadow from '../TitleShadow';

const HomeAnimation = () => {
  const greensockRef = useRef<HTMLImageElement>(null);

  useIsomorphicLayoutEffect(() => {
    // intro animations
    // ref is an object with a current property that holds the DOM element
    const timeline = gsap.timeline({
      // yPercent: -50,
      scrollTrigger: {
        trigger: '.about-container',
        scrub: true,
        // start: 'top',
        // end: '+=500px',
      },
      duration: 0.1,
      ease: 'none',
    });
    timeline
      .fromTo(greensockRef.current, { opacity: 0.2 }, { opacity: 1 })
      .to(greensockRef.current, {
        opacity: 0.2,
        delay: 0.5,
      });

    // gsap.to(greensockRef.current, {
    //   opacity: 1,
    //   delay: 3,
    //   duration: 1,
    //   y: 0,
    //   ease: 'elastic.out',
    // })

    // gsap.to(plusRef.current, {
    //   opacity: 1,
    //   rotationY: -720,
    //   delay: 3,
    //   duration: 2,
    //   y: 0,
    //   transformOrigin: '50% 50%',
    //   ease: 'power4.out',
    // })

    // gsap.to(nextJsRef.current, {
    //   opacity: 1,
    //   delay: 3.2,
    //   duration: 1,
    //   y: 0,
    //   ease: 'bounce.out',
    // })
  }, []);

  return (
    <div className="section-about bg-primary py-24" style={{ position: 'relative' }}>
      <div className="about-container container mx-auto max-w-screen-3xl px-6 pt-28">
        <div
          className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-8"
          ref={greensockRef}
        >
          <div className="pContent md:pr-0 lg:pr-28">
            <TitleShadow prologue="เราคือ" title="นี่...เราเอง" subtitle="It’s me" />
            <p className="mb-4 text-white md:mb-8 xl:text-2xl">
              "We are a proficient Software House and professional development team,
              capable of collaborating effectively with anyone."
            </p>
            <p className="mb-4 text-white md:mb-8 xl:text-2xl">
              "เราเป็นทีมพัฒนามืออาชีพที่มีความเชี่ยวชาญและสามารถทำงานร่วมกับทุกคนได้อย่างมีประสิทธิภาพ"
            </p>
          </div>
          <div className="pContent">
            <TitleShadow prologue="เริ่มจาก" title="เริ่มจาก..." subtitle="Founded" />
            <p className="mb-4 text-white md:mb-8">
              เราเริ่มจากทีมพัฒนาเล็ก ๆ ที่รักและมีความเชี่ยวชาญในการ พัฒนาซอฟต์แวร์,
              พัฒนาเว็บไซต์, และ พัฒนาแอปพลิเคชัน เราก่อตั้ง Twin Synergy ตั้งแต่ปี 2013
              ตลอดเวลาที่ผ่านมาเราได้สร้างสรรค์ผลงานที่มีคุณภาพให้กับลูกค้าทั้งรายเล็กและรายใหญ่มาแล้วมากมาย
            </p>
            <p className="mb-4 text-white md:mb-8">
              และด้วยประสบการณ์ที่มากกว่า 12 ปี
              ทำให้เราได้รับความไว้วางใจในการพัฒนาจากลูกค้าผู้มีพระคุณเป็นอย่างมาก
              ไม่ว่าจะเป็นการ พัฒนาซอฟต์แวร์ ตามกำหนดของลูกค้า, การพัฒนาเว็บไซต์,
              การพัฒนาแอปพลิเคชัน, รวมไปถึงการ รับทำเว็บไซต์
              และการพัฒนาแพลตฟอร์มให้กับลูกค้า ปัจจุบันเราได้เพิ่มทุนจดทะเบียนเป็น
              10,000,000 บาท
              พร้อมทั้งขยายทีมพัฒนาเพิ่มขึ้นเพื่อรองรับลูกค้าที่เพิ่มขึ้นในอนาคต
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeAnimation;
